import { CircularProgress, Stack } from '@mui/material';

type Props = { isContained?: boolean; ariaLabel?: string };
export default function Loading({ ariaLabel, isContained = false }: Props) {
  return (
    <Stack
      sx={{
        height: isContained ? '100%' : '100vh',
        width: isContained ? '100%' : '100vw',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress aria-label={ariaLabel ?? 'Loading...'} />
    </Stack>
  );
}
