// react
import { useSession } from 'next-auth/react';
import React, { useContext, useEffect, useState } from 'react';
// components
import Loading from '@/components/common/loading/Loading';
import { Role } from '@/components/dynamic-nav/types';
// hooks
import useLocalStorage from '@/hooks/useLocalStorage';
import { Role as UserRole } from '@/types/user';

const locales = [
  'en',
  'fr',
] as const;
type Locale = (typeof locales)[number];

export type IRoleOption = {
  name: string;
  value: Role;
};

export type ISettings = {
  navOpen: boolean;
  guidanceOpen: boolean;
  locale: Locale;
};

export type IRoleManagement = {
  role: Role;
  availableRoles: IRoleOption[];
  setRole: (role: Role) => void;
};

export type ISettingsContext = ISettings &
  IRoleManagement & {
    toggleNav: () => void;
    toggleGuidance: () => void;
    openGuidance: () => void;
  };

const defaultSettings = {
  navOpen: true,
  guidanceOpen: false,
  locale: 'en' as Locale,
  showNewSubmit: false,
};

const defaultSettingsContext = {
  ...defaultSettings,
  toggleNav: () => undefined,
  toggleGuidance: () => undefined,
  openGuidance: () => undefined,
  setLocale: () => undefined,
  role: Role.UNKNOWN,
  availableRoles: [],
  setRole: () => undefined,
};

export const SettingsContext = React.createContext<ISettingsContext>(defaultSettingsContext);

export default function SettingsProvider({ children }: Readonly<{ children: React.ReactNode }>) {
  const { data: session } = useSession();

  const [
    loading,
    setLoading,
  ] = useState(true);

  const [
    availableRoles,
    setAvailableRoles,
  ] = useState<
    {
      name: string;
      value: Role;
    }[]
  >([]);

  const [
    role,
    setRole,
  ] = useState<Role>(Role.UNKNOWN);

  useEffect(() => {
    const userRoles: UserRole[] = session?.user?.roles || [];

    const roleMappings: { prefix: UserRole; role: Role; name: string }[] = [
      { prefix: UserRole.AUTHORITY_PREFIX, role: Role.AUTHORITY, name: 'Authority' },
      { prefix: UserRole.DISCLOSER_PREFIX, role: Role.DISCLOSER, name: 'Discloser' },
      { prefix: UserRole.CDP_ADMIN, role: Role.CDP_ADMIN, name: 'CDP Admin' },
    ];

    const roles: IRoleOption[] = [];

    roleMappings.forEach(mapping => {
      if (userRoles.some(role => role.startsWith(mapping.prefix))) {
        roles.push({
          name: mapping.name,
          value: mapping.role,
        });
      }
    });
    if (session && role === Role.UNKNOWN) {
      if (roles.length === 1) {
        setRole(roles[0].value);
      } else {
        const values = roles.map(role => role.value);
        const priorityOrder = [
          Role.CDP_ADMIN,
          Role.AUTHORITY,
          Role.DISCLOSER,
        ];
        const highestPriority = priorityOrder.find(role => values.includes(role));
        if (highestPriority && !priorityOrder.includes(role as unknown as Role)) {
          setRole(highestPriority);
        }
      }
    }

    setAvailableRoles(roles);
  }, [
    session?.user.roles,
  ]);

  const [
    navOpen,
    setNavOpen,
  ] = useLocalStorage<boolean>('nav', defaultSettings.navOpen);

  const [
    guidanceOpen,
    setGuidanceOpen,
  ] = useLocalStorage<boolean>('guidance', defaultSettings.guidanceOpen);

  useEffect(() => {
    setLoading(false);
  }, []);

  const toggleNav = () => setNavOpen(!navOpen);
  const toggleGuidance = () => setGuidanceOpen(!guidanceOpen);
  const openGuidance = () => setGuidanceOpen(true);

  return (
    <SettingsContext.Provider
      value={{
        navOpen,
        toggleNav,
        guidanceOpen,
        toggleGuidance,
        openGuidance,
        locale: defaultSettings.locale,
        role: role,
        availableRoles: availableRoles,
        setRole: setRole,
      }}
    >
      {loading ? <Loading /> : children}
    </SettingsContext.Provider>
  );
}

export const useSettings = () => {
  return useContext(SettingsContext);
};
