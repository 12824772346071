// config
// 3rd
import { graphql } from 'msw';
import { responseConfig } from '@config';

const responseServer = graphql.link(responseConfig.graphql);

const expressions = [
  {
    questionId: '20',
    rowId: 'a8d5b40d-bd7f-4a51-8ad3-d1d685f0c999',
    leadingQuestionId: '21',
    postfix: '[["21","3","18"],["21","3","19"],"/"]',
    __typename: 'Expression',
  },
];

export const getExpressionsMock = responseServer.query('Expressions', (_, res, ctx) => res(ctx.data({ expressions })));
