// config
// 3rd
import { graphql } from 'msw';
import { responseConfig } from '@config';

export const questionnaireInstance = {
  id: '1',
  name: 'Questionnaire 2023',
  cycle: 2023,
  status: 'ACTIVE',
  metadata: {
    status: 'IN_PROGRESS',
    startDate: '2023-01-01T00:00:00.000Z',
    dueDate: '2023-12-31T00:00:00.000Z',
    orgDetailsDate: '2023-06-10T00:00:00.000Z',
    participationDate: '2023-05-20T00:00:00.000Z',
    responseVersion: 3,
    __typename: 'QuestionnaireMetadata',
  },
  sections: [
    {
      id: '0',
      title: 'Introduction',
      description:
        'Welcome to our dynamic questionnaire! As the world faces unprecedented environmental challenges, your insights and opinions take center stage in this interactive survey.',
      questions: [
        {
          id: '2',
          blankOptions: -1,
          rowId: null,
          refId: '2',
          sectionId: '0',
          text: 'Select the closest country',
          popupHelpText: 'You can choose a single option',
          simpleHelpText:
            'The energy-related activities that you select in response to C8.2 determine which energy breakdowns you will be prompted to respond to in the proceeding questions. Please note, if your response to C8.2 is amended, data in dependent questions may be erased.',
          prefix: null,
          order: 2,
          isMandatory: true,
          group: null,
          __typename: 'Question',
          type: {
            type: 'SINGLE_SELECT',
            options: [
              'United Kingdom',
              'France',
            ],
            __typename: 'QuestionTypeSingleSelect',
          },
        },
        {
          id: '4',
          blankOptions: -1,
          rowId: null,
          refId: '4',
          sectionId: '0',
          text: 'UPLOAD your certificate of ownership',
          popupHelpText: null,
          simpleHelpText: null,
          prefix: null,
          order: 4,
          isMandatory: true,
          group: null,
          __typename: 'Question',
          type: {
            type: 'UPLOAD',
            __typename: 'QuestionTypeUpload',
          },
        },
        {
          id: '5',
          blankOptions: -1,
          rowId: null,
          refId: '5',
          sectionId: '0',
          text: 'Which products do you use in the production line',
          popupHelpText: null,
          simpleHelpText: null,
          prefix: null,
          order: 5,
          isMandatory: true,
          group: null,
          __typename: 'Question',
          type: {
            type: 'MULTI_SELECT',
            isOther: false,
            options: [
              'Product 3',
            ],
            __typename: 'QuestionTypeMultiSelect',
          },
        },
        {
          id: '6',
          blankOptions: -1,
          rowId: null,
          refId: '6',
          sectionId: '0',
          text: 'Input the ratio of employees to desks',
          popupHelpText: "Ratio over last year's data",
          simpleHelpText: null,
          prefix: null,
          order: 6,
          isMandatory: true,
          group: null,
          __typename: 'Question',
          type: {
            type: 'NUMERIC',
            __typename: 'QuestionTypeNumeric',
          },
        },
        {
          id: '8',
          blankOptions: -1,
          rowId: null,
          refId: '8',
          sectionId: '0',
          text: 'How would you describe the theory of computing in simple terms',
          popupHelpText: null,
          simpleHelpText: null,
          prefix: null,
          order: 8,
          isMandatory: true,
          group: null,
          __typename: 'Question',
          type: {
            type: 'FREE_TEXT',
            __typename: 'QuestionTypeFreeText',
          },
        },
        {
          id: '9',
          blankOptions: -1,
          rowId: null,
          refId: '9',
          sectionId: '0',
          text: 'How much do you invest in X?',
          popupHelpText: null,
          simpleHelpText: null,
          prefix: null,
          order: 9,
          isMandatory: true,
          group: null,
          __typename: 'Question',
          type: {
            type: 'CURRENCY',
            __typename: 'QuestionTypeCurrency',
          },
        },
        {
          id: '10',
          blankOptions: -1,
          rowId: null,
          refId: '10',
          sectionId: '0',
          text: 'When did you start activity X?',
          popupHelpText: null,
          simpleHelpText: null,
          prefix: null,
          order: 10,
          isMandatory: true,
          group: null,
          __typename: 'Question',
          type: {
            type: 'DATE',
            rules: {
              minDate: '2023-01-01',
              maxDate: '2023-12-31',
              __typename: 'QuestionTypeDateRules',
            },
            __typename: 'QuestionTypeDate',
          },
        },
        {
          id: '14',
          blankOptions: -1,
          rowId: null,
          refId: '14',
          sectionId: '0',
          text: 'Complex Question',
          popupHelpText: null,
          simpleHelpText: null,
          prefix: null,
          order: 11,
          isMandatory: true,
          group: null,
          __typename: 'Question',
          type: {
            type: 'COMPLEX',
            isDynamic: null,
            display: 'Tabs',
            rows: [
              {
                id: '1',
                title: 'C1Tab 1',
                order: 1,
                __typename: 'Row',
              },
              {
                id: '2',
                title: 'C2Tab 2',
                order: 2,
                __typename: 'Row',
              },
            ],
            columns: [
              {
                id: '11',
                blankOptions: -1,
                text: 'Renewable',
                order: 1,
                rowId: null,
                refId: '11',
                sectionId: '0',
                popupHelpText: null,
                simpleHelpText: null,
                prefix: null,
                isMandatory: true,
                group: {
                  id: '1',
                  title: 'Consumption',
                  order: 0,
                  __typename: 'QuestionGroup',
                },
                __typename: 'Question',
                type: {
                  type: 'NUMERIC',
                  __typename: 'QuestionTypeNumeric',
                },
              },
              {
                id: '13',
                blankOptions: -1,
                text: 'Special',
                order: 1,
                rowId: null,
                refId: '13',
                sectionId: '0',
                popupHelpText: null,
                simpleHelpText: null,
                prefix: null,
                isMandatory: true,
                group: {
                  id: '2',
                  title: 'Special Group',
                  order: 1,
                  __typename: 'QuestionGroup',
                },
                __typename: 'Question',
                type: {
                  type: 'NUMERIC',
                  __typename: 'QuestionTypeNumeric',
                },
              },
              {
                id: '12',
                blankOptions: -1,
                text: 'Non-Renewable',
                order: 2,
                rowId: null,
                refId: '12',
                sectionId: '0',
                popupHelpText: null,
                simpleHelpText: null,
                prefix: null,
                isMandatory: true,
                group: {
                  id: '1',
                  title: 'Consumption',
                  order: 0,
                  __typename: 'QuestionGroup',
                },
                __typename: 'Question',
                type: {
                  type: 'NUMERIC',
                  __typename: 'QuestionTypeNumeric',
                },
              },
            ],
            __typename: 'QuestionTypeComplex',
          },
        },
        {
          id: '15',
          blankOptions: -1,
          rowId: null,
          refId: '15',
          sectionId: '0',
          text: 'Emission Aggregation',
          popupHelpText: null,
          simpleHelpText: null,
          prefix: null,
          order: 12,
          isMandatory: true,
          group: null,
          __typename: 'Question',
          type: {
            type: 'NUMERIC',
            __typename: 'QuestionTypeNumeric',
          },
        },
        {
          id: '16',
          blankOptions: -1,
          rowId: null,
          refId: '16',
          sectionId: '0',
          text: 'What is your favourite city?',
          popupHelpText: null,
          simpleHelpText: null,
          prefix: null,
          order: 13,
          isMandatory: true,
          group: null,
          __typename: 'Question',
          type: {
            type: 'GROUPED_OPTIONS_SINGLE',
            groupedoptions: [
              {
                name: 'France',
                options: [
                  'Paris',
                  'Lyon',
                  'Marseille',
                ],
                __typename: 'GroupedOption',
              },
              {
                name: 'Morocco',
                options: [
                  'Casablanca',
                  'Fez',
                  'Tangier',
                ],
                __typename: 'GroupedOption',
              },
              {
                name: 'United Kingdom',
                options: [
                  'London',
                  'Manchester',
                  'Birmingham',
                ],
                __typename: 'GroupedOption',
              },
            ],
            groupLabel: 'Country',
            optionLabel: 'City',
            __typename: 'QuestionTypeGroupedOptionsSingle',
          },
        },
        {
          id: '17',
          blankOptions: -1,
          rowId: null,
          refId: '17',
          sectionId: '0',
          text: 'What are your favourite cities?',
          popupHelpText: null,
          simpleHelpText: null,
          prefix: null,
          order: 14,
          isMandatory: true,
          group: null,
          __typename: 'Question',
          type: {
            type: 'GROUPED_OPTIONS_MULTI',
            groupedoptions: [
              {
                name: 'France',
                options: [
                  'Paris',
                  'Lyon',
                  'Marseille',
                ],
                __typename: 'GroupedOption',
              },
              {
                name: 'Morocco',
                options: [
                  'Casablanca',
                  'Fez',
                  'Tangier',
                ],
                __typename: 'GroupedOption',
              },
              {
                name: 'United Kingdom',
                options: [
                  'London',
                  'Manchester',
                  'Birmingham',
                ],
                __typename: 'GroupedOption',
              },
            ],
            groupLabel: 'Country',
            optionLabel: 'City',
            __typename: 'QuestionTypeGroupedOptionsMulti',
          },
        },
      ],
      infoBlocks: [],
      __typename: 'Section',
    },
    {
      id: '1',
      title: 'Governance',
      description:
        'In a world that is constantly evolving, the structures and mechanisms of governance play a crucial role in shaping societies. This interactive survey is designed to adapt to your insights',
      questions: [
        {
          id: '21',
          blankOptions: -1,
          rowId: null,
          refId: '21',
          sectionId: '1',
          text: 'Auto calc complex questio',
          popupHelpText: null,
          simpleHelpText: null,
          prefix: null,
          order: 1,
          isMandatory: true,
          group: null,
          __typename: 'Question',
          type: {
            type: 'COMPLEX',
            isDynamic: true,
            display: 'Tabs',
            rows: [
              {
                id: '3',
                title: 'CA',
                order: 1,
                __typename: 'Row',
              },
            ],
            columns: [
              {
                id: '18',
                blankOptions: -1,
                text: 'Renewable',
                order: 1,
                rowId: null,
                refId: '18',
                sectionId: '1',
                popupHelpText: null,
                simpleHelpText: null,
                prefix: null,
                isMandatory: true,
                group: {
                  id: '3',
                  title: 'Input',
                  order: 0,
                  __typename: 'QuestionGroup',
                },
                __typename: 'Question',
                type: {
                  type: 'NUMERIC',
                  __typename: 'QuestionTypeNumeric',
                },
              },
              {
                id: '20',
                blankOptions: -1,
                text: 'Special',
                order: 1,
                rowId: null,
                refId: '20',
                sectionId: '1',
                popupHelpText: null,
                simpleHelpText: null,
                prefix: null,
                isMandatory: true,
                group: {
                  id: '4',
                  title: 'Output',
                  order: 1,
                  __typename: 'QuestionGroup',
                },
                __typename: 'Question',
                type: {
                  type: 'NUMERIC',
                  __typename: 'QuestionTypeNumeric',
                },
              },
              {
                id: '19',
                blankOptions: -1,
                text: 'Non-Renewable',
                order: 2,
                rowId: null,
                refId: '19',
                sectionId: '1',
                popupHelpText: null,
                simpleHelpText: null,
                prefix: null,
                isMandatory: true,
                group: {
                  id: '3',
                  title: 'Input',
                  order: 0,
                  __typename: 'QuestionGroup',
                },
                __typename: 'Question',
                type: {
                  type: 'NUMERIC',
                  __typename: 'QuestionTypeNumeric',
                },
              },
            ],
            __typename: 'QuestionTypeComplex',
          },
        },
        {
          id: '22',
          blankOptions: -1,
          rowId: null,
          refId: '22',
          sectionId: '1',
          text: 'Select the closest country',
          popupHelpText: 'You can choose a single option',
          simpleHelpText: null,
          prefix: null,
          order: 2,
          isMandatory: true,
          group: null,
          __typename: 'Question',
          type: {
            type: 'SINGLE_SELECT',
            options: [
              'Morocco',
              'United Kingdom',
              'France',
            ],
            __typename: 'QuestionTypeSingleSelect',
          },
        },
        {
          id: '23',
          blankOptions: -1,
          rowId: null,
          refId: '23',
          sectionId: '1',
          text: 'Describe your company',
          popupHelpText: 'describe in simple terms you company',
          simpleHelpText: null,
          prefix: null,
          order: 3,
          isMandatory: false,
          group: null,
          __typename: 'Question',
          type: {
            type: 'FREE_TEXT',
            __typename: 'QuestionTypeFreeText',
          },
        },
        {
          id: '24',
          blankOptions: -1,
          rowId: null,
          refId: '24',
          sectionId: '1',
          text: 'UPLOAD your certificate of ownership',
          popupHelpText: null,
          simpleHelpText: null,
          prefix: null,
          order: 4,
          isMandatory: true,
          group: null,
          __typename: 'Question',
          type: {
            type: 'UPLOAD',
            __typename: 'QuestionTypeUpload',
          },
        },
        {
          id: '25',
          blankOptions: -1,
          rowId: null,
          refId: '25',
          sectionId: '1',
          text: 'Which products do you use in the production line',
          popupHelpText: null,
          simpleHelpText: null,
          prefix: null,
          order: 5,
          isMandatory: true,
          group: null,
          __typename: 'Question',
          type: {
            type: 'MULTI_SELECT',
            isOther: true,
            options: [
              'Product 1',
              'Product 2',
              'Product 3',
            ],
            __typename: 'QuestionTypeMultiSelect',
          },
        },
        {
          id: '26',
          blankOptions: -1,
          rowId: null,
          refId: '26',
          sectionId: '1',
          text: 'Input the ratio of employees to desks',
          popupHelpText: "Ratio over last year's data",
          simpleHelpText: null,
          prefix: null,
          order: 6,
          isMandatory: true,
          group: null,
          __typename: 'Question',
          type: {
            type: 'NUMERIC',
            __typename: 'QuestionTypeNumeric',
          },
        },
        {
          id: '28',
          blankOptions: -1,
          rowId: null,
          refId: '28',
          sectionId: '1',
          text: 'How would you describe the theory of computing in simple terms',
          popupHelpText: null,
          simpleHelpText: null,
          prefix: null,
          order: 8,
          isMandatory: true,
          group: null,
          __typename: 'Question',
          type: {
            type: 'FREE_TEXT',
            __typename: 'QuestionTypeFreeText',
          },
        },
        {
          id: '29',
          blankOptions: -1,
          rowId: null,
          refId: '29',
          sectionId: '1',
          text: 'How much do you invest in X?',
          popupHelpText: null,
          simpleHelpText: null,
          prefix: null,
          order: 9,
          isMandatory: true,
          group: null,
          __typename: 'Question',
          type: {
            type: 'CURRENCY',
            __typename: 'QuestionTypeCurrency',
          },
        },
        {
          id: '30',
          blankOptions: -1,
          rowId: null,
          refId: '30',
          sectionId: '1',
          text: 'When did you start activity X?',
          popupHelpText: null,
          simpleHelpText: null,
          prefix: null,
          order: 10,
          isMandatory: true,
          group: null,
          __typename: 'Question',
          type: {
            type: 'DATE',
            rules: {
              minDate: '2023-01-01',
              maxDate: '2023-12-31',
              __typename: 'QuestionTypeDateRules',
            },
            __typename: 'QuestionTypeDate',
          },
        },
        {
          id: '39',
          blankOptions: -1,
          rowId: null,
          refId: '39',
          sectionId: '1',
          text: 'Complex Question',
          popupHelpText: null,
          simpleHelpText: null,
          prefix: null,
          order: 11,
          isMandatory: true,
          group: null,
          __typename: 'Question',
          type: {
            type: 'COMPLEX',
            isDynamic: null,
            display: 'Table',
            rows: [
              {
                id: '10',
                title: 'C2Tab 7',
                order: 7,
                __typename: 'Row',
              },
              {
                id: '11',
                title: 'C2Tab 8',
                order: 8,
                __typename: 'Row',
              },
              {
                id: '12',
                title: 'C2Tab 9',
                order: 9,
                __typename: 'Row',
              },
              {
                id: '13',
                title: 'C2Tab 10',
                order: 10,
                __typename: 'Row',
              },
              {
                id: '4',
                title: 'C1Tab 1',
                order: 1,
                __typename: 'Row',
              },
              {
                id: '5',
                title: 'C2Tab 2',
                order: 2,
                __typename: 'Row',
              },
              {
                id: '6',
                title: 'C2Tab 3',
                order: 3,
                __typename: 'Row',
              },
              {
                id: '7',
                title: 'C2Tab 4',
                order: 4,
                __typename: 'Row',
              },
              {
                id: '8',
                title: 'C2Tab 5',
                order: 5,
                __typename: 'Row',
              },
              {
                id: '9',
                title: 'C2Tab 6',
                order: 6,
                __typename: 'Row',
              },
            ],
            columns: [
              {
                id: '33',
                blankOptions: -1,
                text: 'Special',
                order: 1,
                rowId: null,
                refId: '33',
                sectionId: '1',
                popupHelpText: null,
                simpleHelpText: null,
                prefix: null,
                isMandatory: true,
                group: {
                  id: '6',
                  title: 'Special Group',
                  order: 1,
                  __typename: 'QuestionGroup',
                },
                __typename: 'Question',
                type: {
                  type: 'NUMERIC',
                  __typename: 'QuestionTypeNumeric',
                },
              },
              {
                id: '31',
                blankOptions: -1,
                text: 'Renewable',
                order: 1,
                rowId: null,
                refId: '31',
                sectionId: '1',
                popupHelpText: null,
                simpleHelpText: null,
                prefix: null,
                isMandatory: true,
                group: {
                  id: '5',
                  title: 'Consumption',
                  order: 0,
                  __typename: 'QuestionGroup',
                },
                __typename: 'Question',
                type: {
                  type: 'NUMERIC',
                  __typename: 'QuestionTypeNumeric',
                },
              },
              {
                id: '32',
                blankOptions: -1,
                text: 'Non-Renewable',
                order: 2,
                rowId: null,
                refId: '32',
                sectionId: '1',
                popupHelpText: null,
                simpleHelpText: null,
                prefix: null,
                isMandatory: true,
                group: {
                  id: '5',
                  title: 'Consumption',
                  order: 0,
                  __typename: 'QuestionGroup',
                },
                __typename: 'Question',
                type: {
                  type: 'NUMERIC',
                  __typename: 'QuestionTypeNumeric',
                },
              },
              {
                id: '34',
                blankOptions: -1,
                text: 'details about your numbers',
                order: 3,
                rowId: null,
                refId: '34',
                sectionId: '1',
                popupHelpText: null,
                simpleHelpText: null,
                prefix: null,
                isMandatory: true,
                group: {
                  id: '5',
                  title: 'Consumption',
                  order: 0,
                  __typename: 'QuestionGroup',
                },
                __typename: 'Question',
                type: {
                  type: 'FREE_TEXT',
                  __typename: 'QuestionTypeFreeText',
                },
              },
              {
                id: '35',
                blankOptions: -1,
                text: 'Choose a degradation option',
                order: 4,
                rowId: null,
                refId: '35',
                sectionId: '1',
                popupHelpText: null,
                simpleHelpText: null,
                prefix: null,
                isMandatory: true,
                group: {
                  id: '5',
                  title: 'Consumption',
                  order: 0,
                  __typename: 'QuestionGroup',
                },
                __typename: 'Question',
                type: {
                  type: 'SINGLE_SELECT',
                  options: [
                    'Deg Low',
                    'Deg Medium',
                    'Degradation High',
                  ],
                  __typename: 'QuestionTypeSingleSelect',
                },
              },
              {
                id: '36',
                blankOptions: -1,
                text: 'Select all materials generated',
                order: 5,
                rowId: null,
                refId: '36',
                sectionId: '1',
                popupHelpText: null,
                simpleHelpText: null,
                prefix: null,
                isMandatory: true,
                group: {
                  id: '5',
                  title: 'Consumption',
                  order: 0,
                  __typename: 'QuestionGroup',
                },
                __typename: 'Question',
                type: {
                  type: 'MULTI_SELECT',
                  isOther: false,
                  options: [
                    'Carbon',
                    'Oxygen',
                    'Hydrogen',
                    'Calcium',
                    'Uranium',
                    'Potasium',
                  ],
                  __typename: 'QuestionTypeMultiSelect',
                },
              },
              {
                id: '37',
                blankOptions: -1,
                text: 'How much do you generate in Y ?',
                order: 6,
                rowId: null,
                refId: '37',
                sectionId: '1',
                popupHelpText: null,
                simpleHelpText: null,
                prefix: null,
                isMandatory: true,
                group: {
                  id: '5',
                  title: 'Consumption',
                  order: 0,
                  __typename: 'QuestionGroup',
                },
                __typename: 'Question',
                type: {
                  type: 'CURRENCY',
                  __typename: 'QuestionTypeCurrency',
                },
              },
              {
                id: '38',
                blankOptions: -1,
                text: 'Start of you reporting coverage',
                order: 7,
                rowId: null,
                refId: '38',
                sectionId: '1',
                popupHelpText: null,
                simpleHelpText: null,
                prefix: null,
                isMandatory: true,
                group: {
                  id: '5',
                  title: 'Consumption',
                  order: 0,
                  __typename: 'QuestionGroup',
                },
                __typename: 'Question',
                type: {
                  type: 'DATE',
                  rules: {
                    minDate: '2023-01-01',
                    maxDate: '2023-12-31',
                    __typename: 'QuestionTypeDateRules',
                  },
                  __typename: 'QuestionTypeDate',
                },
              },
            ],
            __typename: 'QuestionTypeComplex',
          },
        },
        {
          id: '40',
          blankOptions: -1,
          rowId: null,
          refId: '40',
          sectionId: '1',
          text: 'What is your favourite city?',
          popupHelpText: null,
          simpleHelpText: null,
          prefix: null,
          order: 12,
          isMandatory: true,
          group: null,
          __typename: 'Question',
          type: {
            type: 'GROUPED_OPTIONS_SINGLE',
            groupedoptions: [
              {
                name: 'France',
                options: [
                  'Paris',
                  'Lyon',
                  'Marseille',
                ],
                __typename: 'GroupedOption',
              },
              {
                name: 'Morocco',
                options: [
                  'Casablanca',
                  'Fez',
                  'Tangier',
                ],
                __typename: 'GroupedOption',
              },
              {
                name: 'United Kingdom',
                options: [
                  'London',
                  'Manchester',
                  'Birmingham',
                ],
                __typename: 'GroupedOption',
              },
            ],
            groupLabel: 'Country',
            optionLabel: 'City',
            __typename: 'QuestionTypeGroupedOptionsSingle',
          },
        },
        {
          id: '41',
          blankOptions: -1,
          rowId: null,
          refId: '41',
          sectionId: '1',
          text: 'What are your favourite cities?',
          popupHelpText: null,
          simpleHelpText: null,
          prefix: null,
          order: 13,
          isMandatory: true,
          group: null,
          __typename: 'Question',
          type: {
            type: 'GROUPED_OPTIONS_MULTI',
            groupedoptions: [
              {
                name: 'France',
                options: [
                  'Paris',
                  'Lyon',
                  'Marseille',
                ],
                __typename: 'GroupedOption',
              },
              {
                name: 'Morocco',
                options: [
                  'Casablanca',
                  'Fez',
                  'Tangier',
                ],
                __typename: 'GroupedOption',
              },
              {
                name: 'United Kingdom',
                options: [
                  'London',
                  'Manchester',
                  'Birmingham',
                ],
                __typename: 'GroupedOption',
              },
            ],
            groupLabel: 'Country',
            optionLabel: 'City',
            __typename: 'QuestionTypeGroupedOptionsMulti',
          },
        },
      ],
      __typename: 'Section',
    },
    {
      id: '2',
      title: 'Risks and opportunities',
      description:
        "In a rapidly changing landscape, it's essential to identify the potential pitfalls and prospects that lie ahead",
      questions: [
        {
          id: '42',
          blankOptions: -1,
          rowId: null,
          refId: '42',
          sectionId: '2',
          text: 'Does your organization have a process for identifying, assessing, and responding to climate-related risks and opportunities?',
          popupHelpText: null,
          simpleHelpText: null,
          prefix: null,
          order: 1,
          isMandatory: true,
          group: null,
          __typename: 'Question',
          type: {
            type: 'SINGLE_SELECT',
            options: [
              'Yes',
              'No',
            ],
            __typename: 'QuestionTypeSingleSelect',
          },
        },
        {
          id: '46',
          blankOptions: -1,
          rowId: null,
          refId: '46',
          sectionId: '2',
          text: 'How does your organization define short-, medium- and long-term time horizons?',
          popupHelpText: null,
          simpleHelpText: null,
          prefix: null,
          order: 2,
          isMandatory: true,
          group: null,
          __typename: 'Question',
          type: {
            type: 'COMPLEX',
            isDynamic: null,
            display: 'Tabs',
            rows: [
              {
                id: '14',
                title: 'Short-term',
                order: 1,
                __typename: 'Row',
              },
              {
                id: '15',
                title: 'Medium-term',
                order: 2,
                __typename: 'Row',
              },
              {
                id: '16',
                title: 'Long-term',
                order: 3,
                __typename: 'Row',
              },
            ],
            columns: [
              {
                id: '43',
                blankOptions: -1,
                text: 'From (years)',
                order: 1,
                rowId: null,
                refId: '43',
                sectionId: '2',
                popupHelpText: null,
                simpleHelpText: null,
                prefix: null,
                isMandatory: true,
                group: {
                  id: '7',
                  title: '',
                  order: 0,
                  __typename: 'QuestionGroup',
                },
                __typename: 'Question',
                type: {
                  type: 'NUMERIC',
                  __typename: 'QuestionTypeNumeric',
                },
              },
              {
                id: '44',
                blankOptions: -1,
                text: 'To (years)',
                order: 2,
                rowId: null,
                refId: '44',
                sectionId: '2',
                popupHelpText: null,
                simpleHelpText: null,
                prefix: null,
                isMandatory: true,
                group: {
                  id: '7',
                  title: '',
                  order: 0,
                  __typename: 'QuestionGroup',
                },
                __typename: 'Question',
                type: {
                  type: 'NUMERIC',
                  __typename: 'QuestionTypeNumeric',
                },
              },
              {
                id: '45',
                blankOptions: -1,
                text: 'Comment',
                order: 3,
                rowId: null,
                refId: '45',
                sectionId: '2',
                popupHelpText: null,
                simpleHelpText: null,
                prefix: null,
                isMandatory: false,
                group: {
                  id: '7',
                  title: '',
                  order: 0,
                  __typename: 'QuestionGroup',
                },
                __typename: 'Question',
                type: {
                  type: 'FREE_TEXT',
                  __typename: 'QuestionTypeFreeText',
                },
              },
            ],
            __typename: 'QuestionTypeComplex',
          },
        },
        {
          id: '47',
          blankOptions: -1,
          rowId: null,
          refId: '47',
          sectionId: '2',
          text: 'How does your organization define substantive financial or strategic impact on your business? ',
          popupHelpText: null,
          simpleHelpText: null,
          prefix: null,
          order: 3,
          isMandatory: true,
          group: null,
          __typename: 'Question',
          type: {
            type: 'FREE_TEXT',
            __typename: 'QuestionTypeFreeText',
          },
        },
        {
          id: '53',
          blankOptions: -1,
          rowId: null,
          refId: '53',
          sectionId: '2',
          text: 'Describe your process(es) for identifying, assessing and responding to climate-related risks and opportunities',
          popupHelpText: null,
          simpleHelpText: null,
          prefix: null,
          order: 4,
          isMandatory: true,
          group: null,
          __typename: 'Question',
          type: {
            type: 'COMPLEX',
            isDynamic: true,
            display: 'Tabs',
            rows: [
              {
                id: '17',
                title: 'Risk',
                order: 0,
                __typename: 'Row',
              },
              {
                id: '83d171eb-faf6-4eb5-9de3-29c1fb52f84e',
                title: 'Row 1',
                order: 1,
                __typename: 'Row',
              },
            ],
            columns: [
              {
                id: '48',
                blankOptions: -1,
                text: 'Select all that apply',
                order: 1,
                rowId: null,
                refId: '48',
                sectionId: '2',
                popupHelpText: null,
                simpleHelpText: null,
                prefix: null,
                isMandatory: true,
                group: {
                  id: '8',
                  title: '',
                  order: 0,
                  __typename: 'QuestionGroup',
                },
                __typename: 'Question',
                type: {
                  type: 'MULTI_SELECT',
                  isOther: false,
                  options: [
                    'Direct operations',
                    'Upstream',
                    'Downstream',
                  ],
                  __typename: 'QuestionTypeMultiSelect',
                },
              },
              {
                id: '49',
                blankOptions: -1,
                text: 'Select from',
                order: 2,
                rowId: null,
                refId: '49',
                sectionId: '2',
                popupHelpText: null,
                simpleHelpText: null,
                prefix: null,
                isMandatory: true,
                group: {
                  id: '8',
                  title: '',
                  order: 0,
                  __typename: 'QuestionGroup',
                },
                __typename: 'Question',
                type: {
                  type: 'SINGLE_SELECT',
                  options: [
                    'Integrated into multi-disciplinary company-wide risk management process',
                    'A specific climate-related risk management process',
                  ],
                  __typename: 'QuestionTypeSingleSelect',
                },
              },
              {
                id: '50',
                blankOptions: -1,
                text: 'Select from',
                order: 3,
                rowId: null,
                refId: '50',
                sectionId: '2',
                popupHelpText: null,
                simpleHelpText: null,
                prefix: null,
                isMandatory: true,
                group: {
                  id: '8',
                  title: '',
                  order: 0,
                  __typename: 'QuestionGroup',
                },
                __typename: 'Question',
                type: {
                  type: 'SINGLE_SELECT',
                  options: [
                    'Annually',
                    'Every two years',
                    'Every three years or more',
                    'Not defined',
                  ],
                  __typename: 'QuestionTypeSingleSelect',
                },
              },
              {
                id: '51',
                blankOptions: -1,
                text: 'Select all that applies',
                order: 4,
                rowId: null,
                refId: '51',
                sectionId: '2',
                popupHelpText: null,
                simpleHelpText: null,
                prefix: null,
                isMandatory: true,
                group: {
                  id: '8',
                  title: '',
                  order: 0,
                  __typename: 'QuestionGroup',
                },
                __typename: 'Question',
                type: {
                  type: 'MULTI_SELECT',
                  isOther: true,
                  options: [
                    'Short-term',
                    'Medium-term',
                    'Long-term',
                    'None of the above/ Not defined',
                  ],
                  __typename: 'QuestionTypeMultiSelect',
                },
              },
              {
                id: '52',
                blankOptions: -1,
                text: 'Select all that applies',
                order: 5,
                rowId: null,
                refId: '52',
                sectionId: '2',
                popupHelpText: null,
                simpleHelpText: null,
                prefix: null,
                isMandatory: true,
                group: {
                  id: '8',
                  title: '',
                  order: 0,
                  __typename: 'QuestionGroup',
                },
                __typename: 'Question',
                type: {
                  type: 'FREE_TEXT',
                  __typename: 'QuestionTypeFreeText',
                },
              },
            ],
            __typename: 'QuestionTypeComplex',
          },
        },
        {
          id: '54',
          blankOptions: -1,
          rowId: null,
          refId: '54',
          sectionId: '2',
          text: 'Why does your organization not have a process in place for identifying, assessing, and responding to climate-related risks and opportunities ?',
          popupHelpText: null,
          simpleHelpText: null,
          prefix: null,
          order: 5,
          isMandatory: true,
          group: null,
          __typename: 'Question',
          type: {
            type: 'MULTI_SELECT',
            isOther: true,
            options: [
              'Insufficient data on operations',
              'No instruction from management',
              'We are planning to introduce a climate-related risk management process in the next two years',
              'mportant but not an immediate business priority',
              'Judged to be unimportant, explanation provided',
              'Lack of internal resources',
            ],
            __typename: 'QuestionTypeMultiSelect',
          },
        },
        {
          id: '57',
          blankOptions: -1,
          rowId: null,
          refId: '57',
          sectionId: '2',
          text: 'Do you assess your portfolio’s exposure to climate-related risks and opportunities?',
          popupHelpText: null,
          simpleHelpText: null,
          prefix: null,
          order: 6,
          isMandatory: true,
          group: null,
          __typename: 'Question',
          type: {
            type: 'COMPLEX',
            isDynamic: null,
            display: 'Tabs',
            rows: [
              {
                id: '18',
                title: 'Banking (Bank)',
                order: 1,
                __typename: 'Row',
              },
              {
                id: '19',
                title: 'Investing (Asset manager)',
                order: 2,
                __typename: 'Row',
              },
              {
                id: '20',
                title: 'Investing (Asset owner)',
                order: 3,
                __typename: 'Row',
              },
              {
                id: '21',
                title: 'Insurance underwriting (Insurance company)',
                order: 4,
                __typename: 'Row',
              },
            ],
            columns: [
              {
                id: '55',
                blankOptions: -1,
                text: 'Select from',
                order: 1,
                rowId: null,
                refId: '55',
                sectionId: '2',
                popupHelpText: null,
                simpleHelpText: null,
                prefix: null,
                isMandatory: true,
                group: {
                  id: '9',
                  title: '',
                  order: 0,
                  __typename: 'QuestionGroup',
                },
                __typename: 'Question',
                type: {
                  type: 'SINGLE_SELECT',
                  options: [
                    'Yes',
                    'No, but we plan to in the next two years',
                    'No, and we do not plan to in the next two year',
                  ],
                  __typename: 'QuestionTypeSingleSelect',
                },
              },
              {
                id: '56',
                blankOptions: -1,
                text: "Explain why your portfolio's exposure is not assessed and your plans to address this in the future",
                order: 2,
                rowId: null,
                refId: '56',
                sectionId: '2',
                popupHelpText: null,
                simpleHelpText: null,
                prefix: null,
                isMandatory: true,
                group: {
                  id: '9',
                  title: '',
                  order: 0,
                  __typename: 'QuestionGroup',
                },
                __typename: 'Question',
                type: {
                  type: 'FREE_TEXT',
                  __typename: 'QuestionTypeFreeText',
                },
              },
            ],
            __typename: 'QuestionTypeComplex',
          },
        },
      ],
      __typename: 'Section',
    },
    {
      id: '3',
      title: 'Business Strategy',
      description:
        'In a constantly evolving market, crafting a successful business strategy is paramount. This interactive survey is tailored to adapt to your insights',
      questions: [
        {
          id: '58',
          blankOptions: -1,
          rowId: null,
          refId: '58',
          sectionId: '3',
          text: 'Should businesses adapt their strategies in response to changes in the market?',
          popupHelpText: null,
          simpleHelpText: null,
          prefix: null,
          order: 1,
          isMandatory: true,
          group: null,
          __typename: 'Question',
          type: {
            type: 'SINGLE_SELECT',
            options: [
              'Yes',
              'No',
            ],
            __typename: 'QuestionTypeSingleSelect',
          },
        },
        {
          id: '59',
          blankOptions: -1,
          rowId: null,
          refId: '59',
          sectionId: '3',
          text: 'Can a company achieve growth and expansion without a well-executed business strategy?',
          popupHelpText: null,
          simpleHelpText: null,
          prefix: null,
          order: 2,
          isMandatory: true,
          group: null,
          __typename: 'Question',
          type: {
            type: 'SINGLE_SELECT',
            options: [
              'Yes',
              'No',
            ],
            __typename: 'QuestionTypeSingleSelect',
          },
        },
        {
          id: '60',
          blankOptions: -1,
          rowId: null,
          refId: '60',
          sectionId: '3',
          text: 'Should businesses invest in building a strong brand to support their strategic goals?',
          popupHelpText: null,
          simpleHelpText: null,
          prefix: null,
          order: 3,
          isMandatory: true,
          group: null,
          __typename: 'Question',
          type: {
            type: 'SINGLE_SELECT',
            options: [
              'Yes',
              'No',
            ],
            __typename: 'QuestionTypeSingleSelect',
          },
        },
        {
          id: '61',
          blankOptions: -1,
          rowId: null,
          refId: '61',
          sectionId: '3',
          text: 'Is outsourcing a viable strategy for reducing costs and improving efficiency?',
          popupHelpText: null,
          simpleHelpText: null,
          prefix: null,
          order: 4,
          isMandatory: true,
          group: null,
          __typename: 'Question',
          type: {
            type: 'SINGLE_SELECT',
            options: [
              'Yes',
              'No',
            ],
            __typename: 'QuestionTypeSingleSelect',
          },
        },
      ],
      __typename: 'Section',
    },
  ],
  __typename: 'Questionnaire',
};

const responseServer = graphql.link(responseConfig.graphql);

export const getQuestionnaireMock = responseServer.query('Questionnaire', (_, res, ctx) =>
  res(ctx.data({ questionnaireInstance })),
);
