// react
import { useSession } from 'next-auth/react';
import { PropsWithChildren } from 'react';
// context
import { useI18nContext } from '@/providers/I18nProvider/i18nProvider';
import { Role } from '@/types/user';
// mui
import { BoxProps, Button, Stack } from '@mui/material';

type Props = PropsWithChildren & BoxProps;

export default function I18PhraseLayout({ children }: Props) {
  const { phraseEnabled, handleSwitchPhraseEnabled } = useI18nContext();
  const { data: session } = useSession();

  const hasPhraseAccess = session?.user?.roles?.includes(Role.CONTENT_EDITOR);

  const handleClick = () => {
    handleSwitchPhraseEnabled();
  };

  return (
    <>
      {hasPhraseAccess && (
        <Stack
          direction={'row'}
          sx={{
            zIndex: 10000,
            position: 'fixed',
            width: '73%',
            background: 'transparent',
            right: 0,
            left: 'auto',
          }}
          justifyContent={'flex-end'}
          color={'secondary'}
          pr={2}
          py={2}
          mb={9}
        >
          <Button
            variant="contained"
            onClick={handleClick}
            sx={{
              backgroundColor: theme => theme.palette.primary.main + ' !important',
            }}
          >
            {phraseEnabled ? 'Disabled Phrase' : 'Activate Phrase'}
          </Button>
        </Stack>
      )}
      {children}
    </>
  );
}
