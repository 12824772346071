import React, { PropsWithChildren, createContext, useContext, useMemo, useState } from 'react';

type I18nContextType = {
  phraseEnabled: boolean;
  handleSwitchPhraseEnabled: VoidFunction;
};

const I18nContext = createContext<I18nContextType>({
  phraseEnabled: false,
  handleSwitchPhraseEnabled: () => null,
});

export const useI18nContext = () => useContext(I18nContext);

export const I18nContextProvider = ({ children }: PropsWithChildren) => {
  const [
    phraseEnabled,
    setPhraseEnabled,
  ] = useState(false);
  const handleSwitchPhraseEnabled = () => {
    setPhraseEnabled(prevPhraseEnabled => !prevPhraseEnabled);
  };

  const value = useMemo(() => {
    return { phraseEnabled, handleSwitchPhraseEnabled };
  }, [
    phraseEnabled,
  ]);
  return <I18nContext.Provider value={value}>{children}</I18nContext.Provider>;
};
