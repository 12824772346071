import i18next from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { I18nextPhraseBackend } from '@phrase/i18next-backend';

export const defaultNS = 'common';
export const resources = {};

export type Namespace = 'common' | 'questionnaire' | 'guidance' | 'support-resources' | 'setup' | 'dynamic';
export const ns: Namespace[] = [
  'common',
  'questionnaire',
  'guidance',
  'support-resources',
  'setup',
  'dynamic',
];

const APP_ENV = process.env.NEXT_PUBLIC_APP_ENV ?? 'local';
const USE_PHRASE_OTA = process.env.NEXT_PUBLIC_USE_PHRASE_OTA === 'true';
const DEBUG = APP_ENV !== 'prd';
if (USE_PHRASE_OTA) {
  i18next
    .use(I18nextPhraseBackend)
    .use(initReactI18next)
    .init({
      debug: DEBUG,
      fallbackLng: 'en',
      backend: {
        distribution: process.env.NEXT_PUBLIC_PHRASE_DISTRIBUTION,
        secret: process.env.NEXT_PUBLIC_PHRASE_DISTRIBUTION_SECRET,
        appVersion: '1.0.0',
        cacheExpirationTime: 5 * 60, // 5 minutes
      },
      defaultNS: defaultNS,
      postProcess: [
        'phraseInContextEditor',
      ],
      ns: ns,
    });
} else {
  i18next
    .use(Backend)
    .use(initReactI18next)
    .init({
      debug: DEBUG,
      fallbackLng: 'en',
      defaultNS: defaultNS,
      backend: {
        // Path where resources get loaded from, relative to /public on web server
        loadPath: `${process.env.NEXT_PUBLIC_NEXTAUTH_URL}/locales/{{lng}}/{{ns}}.json`,
      },
      postProcess: [
        'phraseInContextEditor',
      ],
      ns: ns,
    });
}

export default i18next;
