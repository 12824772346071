export class AuthorityTypeIdService {
  static BANKS_PROGRAM() {
    return process.env.NEXT_PUBLIC_AUTHORITY_TYPE_ID_BANKS_PROGRAM ?? '9906c035-ba77-ec11-9120-0050569ce4f2';
  }

  static CAPITAL_MARKETS_SIGNATORY() {
    return (
      process.env.NEXT_PUBLIC_AUTHORITY_TYPE_ID_CAPITAL_MARKETS_SIGNATORY ?? '941bec83-8484-ee11-8179-6045bd905f40'
    );
  }

  static CDP_CAPITAL_MARKETS() {
    return process.env.NEXT_PUBLIC_AUTHORITY_TYPE_ID_CDP_CAPITAL_MARKETS ?? 'e6708f9f-dab6-ee11-a569-6045bd958157';
  }

  static CDP_CAPITAL_MARKETS_CITIES() {
    return (
      process.env.NEXT_PUBLIC_AUTHORITY_TYPE_ID_CDP_CAPITAL_MARKETS_CITIES ?? '37e1e344-e311-ef11-9f89-002248898db9'
    );
  }

  static CDP_CAPITAL_MARKETS_MUNICIPAL_CAMPAIGN() {
    return (
      process.env.NEXT_PUBLIC_AUTHORITY_TYPE_ID_CDP_CAPITAL_MARKETS_MUNICIPAL_CAMPAIGN ??
      'c159b0a1-dab6-ee11-a569-6045bd905f40'
    );
  }

  static CDP_CAPITAL_MARKETS_PUBLIC_AUTHORITIES() {
    return (
      process.env.NEXT_PUBLIC_AUTHORITY_TYPE_ID_CDP_CAPITAL_MARKETS_PUBLIC_AUTHORITIES ??
      'c67398a5-dab6-ee11-a569-6045bd958157'
    );
  }

  static CDP_CAPITAL_MARKETS_SELF_SELECTED() {
    return (
      process.env.NEXT_PUBLIC_AUTHORITY_TYPE_ID_CDP_CAPITAL_MARKETS_SELF_SELECTED ??
      'd2f1f522-fc06-ef11-9f89-000d3abc4a1b'
    );
  }

  static CDP_CAPITAL_MARKETS_STATES_AND_REGIONS() {
    return (
      process.env.NEXT_PUBLIC_AUTHORITY_TYPE_ID_CDP_CAPITAL_MARKETS_STATES_AND_REGIONS ??
      '610fbe26-e311-ef11-9f89-002248898db9'
    );
  }

  static CDP_CITIES() {
    return process.env.NEXT_PUBLIC_AUTHORITY_TYPE_ID_CDP_CITIES ?? '6a1bbeaa-dab6-ee11-a569-6045bd905d39';
  }

  static CDP_STATES_AND_REGIONS() {
    return process.env.NEXT_PUBLIC_AUTHORITY_TYPE_ID_CDP_STATES_AND_REGIONS ?? '86eb97ab-dab6-ee11-a569-6045bd958157';
  }

  static CORPORATE() {
    return process.env.NEXT_PUBLIC_AUTHORITY_TYPE_ID_CORPORATE ?? '58899d19-a2b0-ee11-a569-6045bd905f40';
  }

  static C_STAR() {
    return process.env.NEXT_PUBLIC_AUTHORITY_TYPE_ID_C_STAR ?? '6a76e18f-8484-ee11-8179-6045bd905f40';
  }

  static NET_ZERO_ASSET_MANAGERS() {
    return process.env.NEXT_PUBLIC_AUTHORITY_TYPE_ID_NET_ZERO_ASSET_MANAGERS ?? '55e1e391-dab6-ee11-a569-6045bd905d39';
  }

  static PRIVATE_MARKETS() {
    return process.env.NEXT_PUBLIC_AUTHORITY_TYPE_ID_PRIVATE_MARKETS ?? '53dab0ad-2a08-ed11-912a-0050569ce4f2';
  }

  static RE100() {
    return process.env.NEXT_PUBLIC_AUTHORITY_TYPE_ID_RE100 ?? 'f34f2698-dab6-ee11-a569-6045bd905f18';
  }

  static RE100_MEMBERS() {
    return process.env.NEXT_PUBLIC_AUTHORITY_TYPE_ID_RE100_MEMBERS ?? 'cc3dee28-fc06-ef11-9f89-000d3abc4a1b';
  }

  static SUPPLY_CHAIN() {
    return process.env.NEXT_PUBLIC_AUTHORITY_TYPE_ID_SUPPLY_CHAIN ?? '612be989-8484-ee11-8179-6045bd905f40';
  }

  static SUSTAINABLE_FINANCE() {
    return process.env.NEXT_PUBLIC_AUTHORITY_TYPE_ID_SUSTAINABLE_FINANCE ?? 'b25a7399-dab6-ee11-a569-6045bd958157';
  }

  static isValid(authorityTypeId: string) {
    return [
      AuthorityTypeIdService.CDP_STATES_AND_REGIONS(),
      AuthorityTypeIdService.CDP_CITIES(),
      AuthorityTypeIdService.CDP_CAPITAL_MARKETS(),
      AuthorityTypeIdService.CDP_CAPITAL_MARKETS_SELF_SELECTED(),
      AuthorityTypeIdService.CDP_CAPITAL_MARKETS_PUBLIC_AUTHORITIES(),
      AuthorityTypeIdService.CDP_CAPITAL_MARKETS_CITIES(),
      AuthorityTypeIdService.CDP_CAPITAL_MARKETS_STATES_AND_REGIONS(),
      AuthorityTypeIdService.CAPITAL_MARKETS_SIGNATORY(),
      AuthorityTypeIdService.SUPPLY_CHAIN(),
      AuthorityTypeIdService.SUSTAINABLE_FINANCE(),
      AuthorityTypeIdService.BANKS_PROGRAM(),
      AuthorityTypeIdService.PRIVATE_MARKETS(),
      AuthorityTypeIdService.NET_ZERO_ASSET_MANAGERS(),
      AuthorityTypeIdService.RE100(),
    ].includes(authorityTypeId);
  }

  static isCityAuthority(authorityId: string): boolean {
    return [
      AuthorityTypeIdService.C_STAR(),
      AuthorityTypeIdService.CDP_CITIES(),
      AuthorityTypeIdService.CDP_STATES_AND_REGIONS(),
      AuthorityTypeIdService.CDP_CAPITAL_MARKETS_CITIES(),
      AuthorityTypeIdService.CDP_CAPITAL_MARKETS_STATES_AND_REGIONS(),
    ].includes(authorityId);
  }

  static isCorporateCapitalMarketAuthority(authorityId: string): boolean {
    return [
      AuthorityTypeIdService.CAPITAL_MARKETS_SIGNATORY(),
      AuthorityTypeIdService.CDP_CAPITAL_MARKETS(),
      AuthorityTypeIdService.CDP_CAPITAL_MARKETS_PUBLIC_AUTHORITIES(),
    ].includes(authorityId);
  }
}
