import { ResourceKey } from 'i18next';

export enum Role {
  DISCLOSER = 'discloser',
  AUTHORITY = 'authority',
  CDP_ADMIN = 'cdp_admin',
  UNKNOWN = 'unknown',
}

export enum NotificationType {
  REQUEST = 'request',
}

export type NavLinkItem = {
  type: 'item';
  title: string | ResourceKey;
  path: string;
  icon?: JSX.Element;
  notificationType?: NotificationType;
  subs?: NavLinkItem[];
  isExternalLink?: boolean;
};

export type NavLinkDivider = {
  type: 'divider';
};

type NavLink = NavLinkItem | NavLinkDivider;

export type DynamicNavDataType = {
  role: Role;
  links: NavLink[];
  footer: NavLinkItem[];
};
