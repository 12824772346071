export type UserType = 'authority' | 'discloser';

export enum ReducedRole {
  CDP_ADMIN = 'cdp_admin',
  DISCLOSER_PREFIX = 'discloser',
  AUTHORITY_PREFIX = 'authority',
  UNKNOWN = 'unknown',
}

export enum Role {
  // CDP Roles
  CDP_ADMIN = 'cdp_admin',
  // Authority Roles
  AUTHORITY_ADMIN = 'authority_admin',
  AUTHORITY_CONTRIBUTOR = 'authority_contributor',
  // Discloser Roles
  DISCLOSER_SUBMISSION_LEAD = 'discloser_submission_lead',
  DISCLOSER_CONTRIBUTOR = 'discloser_contributor',
  // Prefix of the roles
  DISCLOSER_PREFIX = 'discloser',
  AUTHORITY_PREFIX = 'authority',
  // Phrase content editor
  CONTENT_EDITOR = 'portal_content_editor',
}

export type AuthUserType = {
  id: string;
  email: string;
  username: string;
  password: string;
  sub: string;
  avatar: string;
  organisationId?: string;
  roles?: Role[];
  oid?: string;
} | null;
