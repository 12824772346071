// config
// 3rd
import { graphql } from 'msw';
import { responseConfig } from '@config';

const responses = [
  {
    id: 1,
    content: null,
    questionId: '21',
    leadingQuestionId: null,
    rowId: null,
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 2,
    content: '0.4',
    questionId: '20',
    leadingQuestionId: '21',
    rowId: '3',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 3,
    content: '20',
    questionId: '18',
    leadingQuestionId: '21',
    rowId: '3',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 4,
    content: '50',
    questionId: '19',
    leadingQuestionId: '21',
    rowId: '3',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 5,
    content: null,
    questionId: '39',
    leadingQuestionId: null,
    rowId: null,
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 6,
    content: 455,
    questionId: '33',
    leadingQuestionId: '39',
    rowId: '10',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 7,
    content: 333,
    questionId: '33',
    leadingQuestionId: '39',
    rowId: '11',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 8,
    content: 3444,
    questionId: '33',
    leadingQuestionId: '39',
    rowId: '12',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 9,
    content: 556,
    questionId: '33',
    leadingQuestionId: '39',
    rowId: '13',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 10,
    content: 4445,
    questionId: '33',
    leadingQuestionId: '39',
    rowId: '4',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 11,
    content: 444,
    questionId: '33',
    leadingQuestionId: '39',
    rowId: '5',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 12,
    content: 4455,
    questionId: '33',
    leadingQuestionId: '39',
    rowId: '6',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 13,
    content: 444,
    questionId: '33',
    leadingQuestionId: '39',
    rowId: '7',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 14,
    content: 4455,
    questionId: '33',
    leadingQuestionId: '39',
    rowId: '8',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 15,
    content: 544,
    questionId: '33',
    leadingQuestionId: '39',
    rowId: '9',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 16,
    content: 454,
    questionId: '31',
    leadingQuestionId: '39',
    rowId: '10',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 17,
    content: 443,
    questionId: '31',
    leadingQuestionId: '39',
    rowId: '11',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 18,
    content: 444,
    questionId: '31',
    leadingQuestionId: '39',
    rowId: '12',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 19,
    content: 556,
    questionId: '31',
    leadingQuestionId: '39',
    rowId: '13',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 20,
    content: 556,
    questionId: '31',
    leadingQuestionId: '39',
    rowId: '4',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 21,
    content: 556,
    questionId: '31',
    leadingQuestionId: '39',
    rowId: '5',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 22,
    content: 556,
    questionId: '31',
    leadingQuestionId: '39',
    rowId: '6',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 23,
    content: 555,
    questionId: '31',
    leadingQuestionId: '39',
    rowId: '7',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 24,
    content: 554,
    questionId: '31',
    leadingQuestionId: '39',
    rowId: '8',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 25,
    content: 445,
    questionId: '31',
    leadingQuestionId: '39',
    rowId: '9',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 26,
    content: 556,
    questionId: '32',
    leadingQuestionId: '39',
    rowId: '10',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 27,
    content: 6665,
    questionId: '32',
    leadingQuestionId: '39',
    rowId: '11',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 28,
    content: 5665,
    questionId: '32',
    leadingQuestionId: '39',
    rowId: '12',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 29,
    content: 56655,
    questionId: '32',
    leadingQuestionId: '39',
    rowId: '13',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 30,
    content: 544,
    questionId: '32',
    leadingQuestionId: '39',
    rowId: '5',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 31,
    content: 554,
    questionId: '32',
    leadingQuestionId: '39',
    rowId: '6',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 32,
    content: 55555,
    questionId: '32',
    leadingQuestionId: '39',
    rowId: '7',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 33,
    content: 444,
    questionId: '32',
    leadingQuestionId: '39',
    rowId: '9',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 34,
    content: 'ttest',
    questionId: '34',
    leadingQuestionId: '39',
    rowId: '10',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 35,
    content: 'test',
    questionId: '34',
    leadingQuestionId: '39',
    rowId: '11',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 36,
    content: 'test',
    questionId: '34',
    leadingQuestionId: '39',
    rowId: '12',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 37,
    content: 'test',
    questionId: '34',
    leadingQuestionId: '39',
    rowId: '13',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 38,
    content: 'test',
    questionId: '34',
    leadingQuestionId: '39',
    rowId: '5',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 39,
    content: 'test',
    questionId: '34',
    leadingQuestionId: '39',
    rowId: '6',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 40,
    content: 'test',
    questionId: '34',
    leadingQuestionId: '39',
    rowId: '7',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 41,
    content: 'test',
    questionId: '34',
    leadingQuestionId: '39',
    rowId: '8',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 42,
    content: 'test',
    questionId: '34',
    leadingQuestionId: '39',
    rowId: '9',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 43,
    content: 'Deg Low',
    questionId: '35',
    leadingQuestionId: '39',
    rowId: '10',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 44,
    content: 'Deg Medium',
    questionId: '35',
    leadingQuestionId: '39',
    rowId: '11',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 45,
    content: 'Degradation High',
    questionId: '35',
    leadingQuestionId: '39',
    rowId: '12',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 46,
    content: 'Degradation High',
    questionId: '35',
    leadingQuestionId: '39',
    rowId: '13',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 47,
    content: 'Deg Medium',
    questionId: '35',
    leadingQuestionId: '39',
    rowId: '4',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 48,
    content: 'Deg Medium',
    questionId: '35',
    leadingQuestionId: '39',
    rowId: '5',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 49,
    content: 'Deg Low',
    questionId: '35',
    leadingQuestionId: '39',
    rowId: '6',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 50,
    content: 'Deg Medium',
    questionId: '35',
    leadingQuestionId: '39',
    rowId: '7',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 51,
    content: 'Deg Medium',
    questionId: '35',
    leadingQuestionId: '39',
    rowId: '8',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 52,
    content: 'Deg Low',
    questionId: '35',
    leadingQuestionId: '39',
    rowId: '9',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 53,
    content: [
      'Carbon',
    ],
    questionId: '36',
    leadingQuestionId: '39',
    rowId: '10',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 54,
    content: [
      'Oxygen',
    ],
    questionId: '36',
    leadingQuestionId: '39',
    rowId: '11',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 55,
    content: [
      'Oxygen',
    ],
    questionId: '36',
    leadingQuestionId: '39',
    rowId: '12',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 56,
    content: [
      'Hydrogen',
      'Oxygen',
    ],
    questionId: '36',
    leadingQuestionId: '39',
    rowId: '13',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 57,
    content: [
      'Oxygen',
      'Calcium',
      'Hydrogen',
    ],
    questionId: '36',
    leadingQuestionId: '39',
    rowId: '4',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 58,
    content: [
      'Carbon',
    ],
    questionId: '36',
    leadingQuestionId: '39',
    rowId: '5',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 59,
    content: [
      'Oxygen',
      'Carbon',
    ],
    questionId: '36',
    leadingQuestionId: '39',
    rowId: '6',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 60,
    content: [
      'Carbon',
    ],
    questionId: '36',
    leadingQuestionId: '39',
    rowId: '7',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 61,
    content: [
      'Carbon',
      'Oxygen',
    ],
    questionId: '36',
    leadingQuestionId: '39',
    rowId: '8',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 62,
    content: [
      'Carbon',
      'Oxygen',
    ],
    questionId: '36',
    leadingQuestionId: '39',
    rowId: '9',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 63,
    content: 4,
    questionId: '37',
    leadingQuestionId: '39',
    rowId: '10',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 64,
    content: 3009,
    questionId: '37',
    leadingQuestionId: '39',
    rowId: '11',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 65,
    content: 334,
    questionId: '37',
    leadingQuestionId: '39',
    rowId: '13',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 66,
    content: 4555,
    questionId: '37',
    leadingQuestionId: '39',
    rowId: '5',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 67,
    content: 66,
    questionId: '37',
    leadingQuestionId: '39',
    rowId: '6',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 68,
    content: 5553,
    questionId: '37',
    leadingQuestionId: '39',
    rowId: '7',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 69,
    content: 3344,
    questionId: '37',
    leadingQuestionId: '39',
    rowId: '8',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 70,
    content: 334,
    questionId: '37',
    leadingQuestionId: '39',
    rowId: '9',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 71,
    content: '2023-10-20T22:00:00.000Z',
    questionId: '38',
    leadingQuestionId: '39',
    rowId: '10',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 72,
    content: '2023-10-14T22:00:00.000Z',
    questionId: '38',
    leadingQuestionId: '39',
    rowId: '11',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 73,
    content: '2023-10-04T22:00:00.000Z',
    questionId: '38',
    leadingQuestionId: '39',
    rowId: '12',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 74,
    content: '2023-10-13T22:00:00.000Z',
    questionId: '38',
    leadingQuestionId: '39',
    rowId: '13',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 75,
    content: '2023-10-28T22:00:00.000Z',
    questionId: '38',
    leadingQuestionId: '39',
    rowId: '4',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 76,
    content: '2023-10-18T22:00:00.000Z',
    questionId: '38',
    leadingQuestionId: '39',
    rowId: '5',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 77,
    content: '2023-10-18T22:00:00.000Z',
    questionId: '38',
    leadingQuestionId: '39',
    rowId: '6',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 78,
    content: '2023-10-18T22:00:00.000Z',
    questionId: '38',
    leadingQuestionId: '39',
    rowId: '7',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 79,
    content: '2023-10-21T22:00:00.000Z',
    questionId: '38',
    leadingQuestionId: '39',
    rowId: '8',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 80,
    content: '2023-10-10T22:00:00.000Z',
    questionId: '38',
    leadingQuestionId: '39',
    rowId: '9',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 81,
    content: 'tes',
    questionId: '34',
    leadingQuestionId: '39',
    rowId: '4',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 82,
    content: 4,
    questionId: '32',
    leadingQuestionId: '39',
    rowId: '4',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 83,
    content: 5,
    questionId: '37',
    leadingQuestionId: '39',
    rowId: '4',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 84,
    content: 7,
    questionId: '37',
    leadingQuestionId: '39',
    rowId: '12',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 85,
    content: 9,
    questionId: '32',
    leadingQuestionId: '39',
    rowId: '8',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 86,
    content: 'United Kingdom',
    questionId: '2',
    leadingQuestionId: null,
    rowId: null,
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 87,
    content: [
      'Product 3',
    ],
    questionId: '5',
    leadingQuestionId: null,
    rowId: null,
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 88,
    content: '1500900',
    questionId: '6',
    leadingQuestionId: null,
    rowId: null,
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 89,
    content: '66969.00',
    questionId: '9',
    leadingQuestionId: null,
    rowId: null,
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 90,
    content: null,
    questionId: '14',
    leadingQuestionId: null,
    rowId: null,
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 91,
    content: '20',
    questionId: '11',
    leadingQuestionId: '14',
    rowId: '2',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 92,
    content: '20',
    questionId: '12',
    leadingQuestionId: '14',
    rowId: '2',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 93,
    content: '30',
    questionId: '13',
    leadingQuestionId: '14',
    rowId: '2',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 94,
    content: '9909',
    questionId: '15',
    leadingQuestionId: null,
    rowId: null,
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 95,
    content: null,
    questionId: '16',
    leadingQuestionId: null,
    rowId: null,
    status: 'SKIPPED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 96,
    content: null,
    questionId: '17',
    leadingQuestionId: null,
    rowId: null,
    status: 'SKIPPED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 97,
    content: null,
    questionId: '4',
    leadingQuestionId: null,
    rowId: null,
    status: 'SKIPPED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 98,
    content: '<p>this is text field</p>',
    questionId: '8',
    leadingQuestionId: null,
    rowId: null,
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 99,
    content: [
      'No instruction from management',
      'Insufficient data on operations',
    ],
    questionId: '54',
    leadingQuestionId: null,
    rowId: null,
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 100,
    content: 'Yes',
    questionId: '42',
    leadingQuestionId: null,
    rowId: null,
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 101,
    content: null,
    questionId: '46',
    leadingQuestionId: null,
    rowId: null,
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 102,
    content: '1000',
    questionId: '43',
    leadingQuestionId: '46',
    rowId: '14',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 103,
    content: '2000',
    questionId: '44',
    leadingQuestionId: '46',
    rowId: '14',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 104,
    content: '<p>test</p>',
    questionId: '45',
    leadingQuestionId: '46',
    rowId: '14',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 105,
    content: '1000',
    questionId: '43',
    leadingQuestionId: '46',
    rowId: '15',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 106,
    content: '3000',
    questionId: '44',
    leadingQuestionId: '46',
    rowId: '15',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 107,
    content: '<p>test</p>',
    questionId: '45',
    leadingQuestionId: '46',
    rowId: '15',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 108,
    content: '1000',
    questionId: '43',
    leadingQuestionId: '46',
    rowId: '16',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 109,
    content: '2000',
    questionId: '44',
    leadingQuestionId: '46',
    rowId: '16',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 110,
    content: '<p>test test</p>',
    questionId: '45',
    leadingQuestionId: '46',
    rowId: '16',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 111,
    content: '<p>test test test</p>',
    questionId: '47',
    leadingQuestionId: null,
    rowId: null,
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 112,
    content: null,
    questionId: '53',
    leadingQuestionId: null,
    rowId: null,
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 113,
    content: [
      'Direct operations',
    ],
    questionId: '48',
    leadingQuestionId: '53',
    rowId: '17',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 114,
    content: 'A specific climate-related risk management process',
    questionId: '49',
    leadingQuestionId: '53',
    rowId: '17',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 115,
    content: [
      'Short-term',
      'Medium-term',
      'N/A/B',
      'N/A',
    ],
    questionId: '51',
    leadingQuestionId: '53',
    rowId: '17',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 116,
    content: '<p>test</p>',
    questionId: '52',
    leadingQuestionId: '53',
    rowId: '17',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 117,
    content: null,
    questionId: '57',
    leadingQuestionId: null,
    rowId: null,
    status: 'SKIPPED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 118,
    content: null,
    questionId: '55',
    leadingQuestionId: '57',
    rowId: '18',
    status: 'UNANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 119,
    content: 'Yes',
    questionId: '58',
    leadingQuestionId: null,
    rowId: null,
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 123,
    content: [
      'Product 4',
    ],
    questionId: '25',
    leadingQuestionId: null,
    rowId: null,
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 124,
    content: 'Yes',
    questionId: '59',
    leadingQuestionId: null,
    rowId: null,
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 125,
    content: 'Yes',
    questionId: '60',
    leadingQuestionId: null,
    rowId: null,
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 126,
    content: 'Yes',
    questionId: '61',
    leadingQuestionId: null,
    rowId: null,
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 127,
    content: 'Annually',
    questionId: '50',
    leadingQuestionId: '53',
    rowId: '17',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 128,
    content: [
      'Direct operations',
    ],
    questionId: '48',
    leadingQuestionId: '53',
    rowId: '83d171eb-faf6-4eb5-9de3-29c1fb52f84e',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 129,
    content: 'Integrated into multi-disciplinary company-wide risk management process',
    questionId: '49',
    leadingQuestionId: '53',
    rowId: '83d171eb-faf6-4eb5-9de3-29c1fb52f84e',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 130,
    content: [
      'Short-term',
      'Medium-term',
    ],
    questionId: '51',
    leadingQuestionId: '53',
    rowId: '83d171eb-faf6-4eb5-9de3-29c1fb52f84e',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
  {
    id: 131,
    content: '<p>test</p>',
    questionId: '52',
    leadingQuestionId: '53',
    rowId: '83d171eb-faf6-4eb5-9de3-29c1fb52f84e',
    status: 'ANSWERED',
    questionType: null,
    attachments: [],
    __typename: 'Response',
  },
];

const responseServer = graphql.link(responseConfig.graphql);

export const getResponsesMock = responseServer.query('Responses', (_, res, ctx) => res(ctx.data({ responses })));
