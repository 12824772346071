import { SnackbarProvider as NotistackProvider, SnackbarKey } from 'notistack';
import { useRef } from 'react';
// icon
import CloseIcon from '@mui/icons-material/Close';
// mui
import { IconButton } from '@mui/material';

type Props = {
  children: React.ReactNode;
};

export default function SnackbarProvider({ children }: Props) {
  const notistackRef = useRef<any>(null);

  const onClose = (key: SnackbarKey) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <NotistackProvider
      ref={notistackRef}
      dense
      maxSnack={3}
      preventDuplicate
      autoHideDuration={3000}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      action={key => (
        <IconButton
          size="small"
          onClick={onClose(key)}
          sx={{ p: 0.5 }}
        >
          <CloseIcon />
        </IconButton>
      )}
    >
      {children}
    </NotistackProvider>
  );
}
