// config
// 3rd
import { graphql } from 'msw';
import { responseConfig } from '@config';

const responseServer = graphql.link(responseConfig.graphql);

const clauses = [
  {
    questionId: '33',
    rowId: '4',
    leadingQuestionId: '39',
    conditions: [
      {
        index: 0,
        questionId: '31',
        value: '10',
        operand: 'eq',
        logicalOperator: null,
        leadingQuestionId: '39',
        rowId: '4',
        __typename: 'Condition',
      },
    ],
    __typename: 'Clause',
  },
  {
    questionId: '59',
    rowId: null,
    leadingQuestionId: null,
    conditions: [
      {
        index: 0,
        questionId: '58',
        value: '"Yes"',
        operand: 'eq',
        logicalOperator: null,
        leadingQuestionId: null,
        rowId: null,
        __typename: 'Condition',
      },
    ],
    __typename: 'Clause',
  },
  {
    questionId: '50',
    rowId: '83d171eb-faf6-4eb5-9de3-29c1fb52f84e',
    leadingQuestionId: '53',
    conditions: [
      {
        index: 0,
        questionId: '49',
        value: '"A specific climate-related risk management process"',
        operand: 'eq',
        logicalOperator: null,
        leadingQuestionId: '53',
        rowId: '83d171eb-faf6-4eb5-9de3-29c1fb52f84e',
        __typename: 'Condition',
      },
    ],
    __typename: 'Clause',
  },
  {
    questionId: '61',
    rowId: null,
    leadingQuestionId: null,
    conditions: [
      {
        index: 0,
        questionId: '60',
        value: '"Yes"',
        operand: 'eq',
        logicalOperator: null,
        leadingQuestionId: null,
        rowId: null,
        __typename: 'Condition',
      },
    ],
    __typename: 'Clause',
  },
  {
    questionId: '60',
    rowId: null,
    leadingQuestionId: null,
    conditions: [
      {
        index: 0,
        questionId: '59',
        value: '"Yes"',
        operand: 'eq',
        logicalOperator: null,
        leadingQuestionId: null,
        rowId: null,
        __typename: 'Condition',
      },
    ],
    __typename: 'Clause',
  },
  {
    questionId: '10',
    rowId: null,
    leadingQuestionId: null,
    conditions: [
      {
        index: 0,
        questionId: '7',
        value: '"Product 4"',
        operand: 'eq',
        logicalOperator: null,
        leadingQuestionId: null,
        rowId: null,
        __typename: 'Condition',
      },
      {
        index: 1,
        questionId: '9',
        value: '10',
        operand: 'eq',
        logicalOperator: 'AND',
        leadingQuestionId: null,
        rowId: null,
        __typename: 'Condition',
      },
      {
        index: 2,
        questionId: '7',
        value: '"Product 7"',
        operand: 'eq',
        logicalOperator: 'OR',
        leadingQuestionId: null,
        rowId: null,
        __typename: 'Condition',
      },
      {
        index: 3,
        questionId: '9',
        value: '100',
        operand: 'gt',
        logicalOperator: 'OR',
        leadingQuestionId: null,
        rowId: null,
        __typename: 'Condition',
      },
    ],
    __typename: 'Clause',
  },
  {
    questionId: '50',
    rowId: '17',
    leadingQuestionId: '53',
    conditions: [
      {
        index: 0,
        questionId: '49',
        value: '"A specific climate-related risk management process"',
        operand: 'eq',
        logicalOperator: null,
        leadingQuestionId: '53',
        rowId: '17',
        __typename: 'Condition',
      },
    ],
    __typename: 'Clause',
  },
  {
    questionId: null,
    rowId: '1',
    leadingQuestionId: '14',
    conditions: [
      {
        index: 0,
        questionId: '5',
        value: '"Product 1"',
        operand: 'eq',
        logicalOperator: null,
        leadingQuestionId: null,
        rowId: null,
        __typename: 'Condition',
      },
    ],
    __typename: 'Clause',
  },
];

export const getClausesMock = responseServer.query('Clauses', (_, res, ctx) => res(ctx.data({ clauses })));
