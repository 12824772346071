import { getClausesMock } from './getClauses';
import { getExpressionsMock } from './getExpressions';
import { getQuestionnaireMock } from './getQuestionnaire';
import { getResponsesMock } from './getResponses';
import { saveQuestionnaireMock } from './saveQuestionnaire';

export const responseMocks = [
  getQuestionnaireMock,
  saveQuestionnaireMock,
  getResponsesMock,
  getClausesMock,
  getExpressionsMock,
];
